@use 'sass:math';
@import "./src/styles/Library";
.check {
    color: $greenHl;
    font-size: 35px;
}

.up {
    color: $blueHl;
    font-size: 35px;
}

.down {
    color: $yellowHl;
    font-size: 35px;
}

.dash {
    font-size: 35px;
}

.x {
    color: $redHl;
    font-size: 35px;
}