@use 'sass:math';
@import "./src/styles/Library";
.image {
    width: 125px;
    height: 100%;
    overflow: hidden;
    margin-top: 5px;
}

.imageWrapper {}

.tableHeading {
    color: $primary;
    font-weight: 600;
    font-size: 1rem;
}

.imageContainer {
    height: 125px;
    width: 170px;
    border: 2px solid #e0e0e0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    img {
        max-width: 100%;
        max-height: 100%;
    }

}