@use 'sass:math';
@import "./src/styles/Library";
.runtimes {
    padding: 0 3rem;
    position: relative;
}

.items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 280px;
    text-align: center;
}

.pace {
    color: $secondary;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;
}

.time {
    color: $dark3;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: .2rem;
}

.distance {
    color: $dark0;
    font-weight: 700;
    font-size: 1.2rem;
}

.column {
    min-height: 6%;
}

.graphic {
    height: 100%;
    width: 56px;
    background-color: red;
    margin: 0 auto;
    border-radius: 5px;
}

.item:nth-child(1) .graphic {
    background-color: $zone5Light;
}
.item:nth-child(2) .graphic {
    background-color: $zone4Light;
}
.item:nth-child(3) .graphic {
    background-color: $zone3Light;
}
.item:nth-child(4) .graphic {
    background-color: $zone2Light;
}
.item:nth-child(5) .graphic {
    background-color: $zone1Light;
}

.labels {
    position: absolute;
    top: 16%;
}