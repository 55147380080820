@use 'sass:math';
@import "./src/styles/Library";
.subHeading {
    font-size: 1.2rem;
    text-align: center;
}

.heading {
    font-size: 1.4rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.subHeading-0 {
    @extend .subHeading;
    color: $secondary;
}

.subHeading-1 {
    @extend .subHeading;
    color: $primary;
}

.heading-0 {
    @extend .heading;
    color: $secondary;
}

.heading-1 {
    @extend .heading;
    color: $primary;
}

.text {
    font-size: 1.1rem;
    text-align: center;
}