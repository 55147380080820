@use 'sass:math';
@import "./src/styles/Library";
//cover page
.cover {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .innerCover {

        .vo2Logo {
            padding: 0 0 2rem 8%;
            max-width: 90%;
            height: auto;
        }

        .borderBlue {
            border-left: 10px solid $primary;
            margin-left: 10%;

            .borderGreen {
                border-left: 10px solid $secondary;
                margin-left: 5px;

                padding: 2rem 0 0 2rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .top {
                    .title {
                        color: $primary;
                        font-size: 3rem;
                        font-weight: 900;

                        span {
                            color: $secondary;
                        }
                    }

                    .blackText {
                        display: flex;
                        font-weight: 900;
                        font-size: 1.5rem;

                        p {
                            padding-right: .5rem
                        }

                        .value {
                            @extend .blackText;
                        }
                    }
                }

                .imageWrapper {
                    width: 30rem;
                    margin: 2rem 0 2rem 0;
                    height: 350px;

                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: left;
                        background: transparent;
                    }
                }

                .athlete {
                    color: $secondary;
                    font-size: 3rem;
                    font-weight: 900;
                }

                .logoRow {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 10%;
                    flex-wrap: wrap;
                    padding-right: 80px;

                    .logoContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 1 0 33%;

                        .logo {
                            width: 100px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}