@use 'sass:math';
@import "./src/styles/Library";
.chartContainer {
    width: 100%;

    .barChart {
        width: 100%;
        position: relative;

        table {
            border-bottom: 1px solid $light2;
        }

        table tr {
            border-top: 1px solid $light2;
        }
    }

    .mileage-bar {
        width: 30px;
        background-color: $secondary;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 0;

    }

    .elite {
        @extend .mileage-bar;
        height: 133px;
    }

    .very-high {
        @extend .mileage-bar;
        height: 114px;
    }

    .high {
        @extend .mileage-bar;
        height: 95px;
    }

    .moderately-high {
        @extend .mileage-bar;
        height: 76px;
    }

    .moderate {
        @extend .mileage-bar;
        height: 57px;
    }

    .low {
        @extend .mileage-bar;
        height: 38px;
    }

    .very-low {
        @extend .mileage-bar;
        height: 19px;
    }

    .none {
        @extend .mileage-bar;
        height: 0px;
    }

    .label {
        color: #326EB6;
        text-align: center;
        width: 100%;
    }

    table {
        color: #939393;
        font-weight: 500;
        font-size: 12px;
        width: 100%;
    }

    table td,
    th {
        text-align: left;
        padding-left: 5px;
        padding-right: 5px;
        vertical-align: middle;
        height: 19px;
    }

    table tr:nth-child(odd) {
        background: #f4f4f4;
    }

    table tr:nth-child(even) {
        background: white
    }

    table td:nth-child(1) {
        text-align: left;
    }

    table td:nth-child(2) {
        text-align: right;
    }
}

.injuryPainArea {
    text-align: center;

    .circle {
        height: 18px;
        width: 18px;
        background-color: #3D5AFE;
        border-radius: 50%;
        margin-right: 4px;
        top: 4px;
        position: relative;
        display: inline-block;
    }

    .subHeadingTitle {
        display: inline-block;
        text-align: left;
        color: $primary;
        font-weight: 700;
        font-size: 18px;
    }
}

.sectionBio {

    .measurements {
        height: 300px;
        width: 90%;
        margin: auto;
        position: relative;
    }

    .imageWrapper {
        width: 80%;
        // position: relative;
    }

    .image1 {
        display: inline-block;
        position: absolute;
        top: 40px;
        left: 50px;

        img {
            width: 80px;
        }
    }

    .image2 {
        display: inline-block;
        position: absolute;
        top: 40px;
        left: 230px;

        img {
            width: 134px;
        }
    }

    .image3 {
        display: inline-block;
        position: absolute;
        top: 63px;
        right: 100px;

        img {
            width: 96px;
        }
    }

    .image4 {
        display: inline-block;
        position: absolute;
        top: 100px;
        right: 250px;

        img {
            width: 160px;
            border: 1px solid #add8e6;
        }
    }

    .height {
        position: absolute;
        left: 0px;
        top: 20px;

    }

    .shoulderWidth {
        position: absolute;
        top: 20px;
        left: 140px;
    }

    .armLength {
        position: absolute;
        top: 20px;
        left: 295px;
    }

    .torsoLength {
        position: absolute;
        top: 20px;
        right: 150px;
    }

    .inseam {
        position: absolute;
        top: 132px;
        left: 140px;
    }

    .armSpan {
        position: absolute;
        right: 358px;
        top: 174px;
        text-align: left;
    }

    .lowerLegLength {
        position: absolute;
        right: 15px;
        top: 185px;
        text-align: left;
        white-space: nowrap;
    }

    .femurLength {
        position: absolute;
        right: 156px;
        top: 185px;
        text-align: right;
    }

    .weight {
        position: absolute;
        left: 50px;
        top: 245px;
    }

    .floorToShoulder {
        position: absolute;
        left: 230px;
        top: 245px;
    }

    .toeTouch {
        position: absolute;
        right: 132px;
        top: 297px;
        text-align: left;
    }

    .toeTouch th {
        vertical-align: top;
        padding: 0px;
    }

    .toeTouch .image {
        position: absolute;
        right: -108px;
        top: -15px;
        height: 100px;
        width: 100px;
    }

    .toeTouch img {

        width: 100%;
        height: 100%;
    }
}

.body-proportions {
    margin-top: 10px;
}

.sectionSubHeading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    row-gap: 10px;
}

.subHeadingTitle {
    color: $primary;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
}

.subHeadingSubTitle {
    color: $primary;
    font-size: 14px;
}