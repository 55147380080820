@use 'sass:math';
@import "./src/styles/Library";
.page {
    min-height: 1090px;
    page-break-before: always;
    page-break-after: always;
    border-radius: 7px;
    border-radius: 0px;
    box-shadow: none !important;
}

.body {
    padding: 20px 20px;
}

.pageHeader {
    .row {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 0;

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .headerSubText {
                color: #939393;
                font-weight: 600;
                font-size: 13px;
            }

            .athleteName {
                color: #326EB6;
                padding-bottom: 0px;
                font-size: 30px;
                margin-top: 15px;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .logo {
                width: 150px;
                height: auto;
            }

            .date {
                color: #939393;
                margin-top: -8px;
                text-align: right;
                font-weight: 600;
                font-size: 13px;
            }
        }
    }

}

.heading {
    background: $lightBlue;
    background: linear-gradient(270deg, $lightBlue 0%, $medBlue 49%, $darkBlue 100%);
    border-bottom: 1px solid $panelBdr;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.heading h1 {
    font-size: 2rem;
    font-weight: 900;
    color: $primaryLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading h2 {
    font-size: 1.5rem;
    font-weight: 900;
    color: $primaryLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
}