@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    h2 {
        font-weight: 900;
    }

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }

    .panel-heading {
        background: $panelBg;
        border-radius: 11px;
    }
}

.head {
    display: flex;
    justify-content: space-between;

    .title {
        font-size: 1.5em;
        padding: 0 0 1em;
        font-weight: 900;
        line-height: 1em;
    }

    .more {
        text-align: right;
        color: $primary;
        font-weight: 600;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
    }

    .more :global svg {
        top: 2px;
        position: relative;
    }
}

.demographic {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    color: $dark4;
    margin-top: 1em;
    margin-bottom: -.5em;
}

.inner {
    padding: 10px 10px 0;
}

.fieldWrap {
    margin: 10px 0 -10px;
}

.rows :global {
    margin: 0 -10px;
    padding: 1em 0 1.4em;
    overflow: hidden;
    background: $panelFooterBg;
    border-bottom: 1px solid $panelBdr;
    border-top: 1px solid $panelBdr;

    .row:not(:first-child) .rangesBar {
        visibility: hidden;
        margin-top: -4px;
    }
}

.row {
    padding: 0 .7em;
}

.cols {
    display: flex;
    align-items: flex-end;
    padding: 0 0.6em;
    gap: 10px;

    .metric {
        flex-basis: 30%;
        font-size: 1.25em;
        font-weight: 900;
        line-height: 1.2em;
        padding-bottom: 4px;
    }

    .measurement {
        flex-basis: 10%;
        font-size: 1.25em;
        font-weight: 900;
        text-align: right;
        padding-bottom: 4px;
        color: $secondary;

        .unit {
            color: $greenHl2;
            font-size: .4em;
            font-weight: 600;
        }
    }

    .rating {
        font-weight: 900;
        color: $secondary;
    }

}

.displayShow {
    margin: -.6rem -8px 0;
    padding: 4px 8px 0;
    flex: 1;
}

.footer {
    padding: .8em 0 1.4em;

    .metric {
        padding: 0;
    }

    .measurement {
        font-size: 2.4em;
        padding-bottom: 0;
        line-height: 1em;

    }
}