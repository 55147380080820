@use 'sass:math';
@import "./src/styles/Library";
  .circulation :global {


      .heart-rate {
          position: relative;
          max-width: 450px;
          margin: 0 auto;

          p {
              font-size: 17px;
              font-weight: 900;
          }

          .image {
              max-width: 50%;
              margin: 0 auto;
          }

          .dark-text {
              color: $zone6Dark;
              position: absolute;
              top: 0%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

          .orange-text {
              color: $orangeText;
              position: absolute;
              top: 17%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

          .yellow-text {
              color: $yellowText;
              position: absolute;
              top: 19%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

          .green-text {
              color: $greenText;
              position: absolute;
              top: 40%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

          .blue-text {
              color: $blueText;
              position: absolute;
              top: 59%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

          .light-text {
              color: $lightBlueText;
              position: absolute;
              top: 90%;
              right: 10%;
              width: 150px;
              display: flex;
              justify-content: space-between;
          }

      }

      .hr-description {
          font-weight: 500;
          font-size: 13px;
          text-align: center;
          margin-top: 20px;
      }



      .peak-hr {
          position: relative;
          display: flex;
          flex-direction: column;

          .heading {
              text-align: center;
              font-weight: 600;
              font-size: 1.3rem;
          }

          p {
              font-size: 15px;
              white-space: nowrap;
          }

          .hr-chart {
              display: flex;
              align-items: flex-end;

              p {
                  font-weight: 900;
              }

              .today-hr {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-end;
                  max-width: 450px;
                  margin: 0 auto;
              }

          }

          .checkmarkImage {
              width: 20px;
              height: 20px;
          }

          .checkmarkContainer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
          }

          .checkmark-center {
              gap: .3rem;
              position: absolute;
              top: 105%;
              right: 41%;
              color: $greenText;
              font-weight: 600;
          }

          .checkmark-start {
              @extend .checkmark-center;
              right: 73%;
          }

          .checkmark-end {
              @extend .checkmark-center;
              right: 7%;
          }
      }

      .image {
          width: 100%;
          display: flex;
          justify-content: center;

          .imageGraph {
              width: 70%;
          }
      }

      .cylinderImage {
          width: 50%;
          display: flex;
          justify-content: flex-end;
      }

  }