@use 'sass:math';
@import "./src/styles/Library";
.up {
    width: 30px;
    height: 30px;
    color: $blueHl;
}

.down {
    width: 30px;
    height: 30px;
    color: $yellowHl;
}

.dash {
    width: 30px;
    height: 30px;
    color: $dark1;
}

.upSmall {
    width: 20px;
    height: 20px;
    color: $blueHl;
}

.downSmall {
    width: 20px;
    height: 20px;
    color: $yellowHl;
}

.dashSmall {
    width: 20px;
    height: 20px;
    color: $dark1;
}

.headingImage {
    width: 125px;
    ;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}