@use 'sass:math';
@import "./src/styles/Library";
.eventCell {
    padding: 0 !important;
}

.eventBox {
    margin-top: 10px;
    margin-bottom: 15px;
    border: 2px solid $panelBdr;
    border-top-width: 1px;
}

.eventBox table .label {
    font-weight: 600 !important;
}

.eventBox .eventsTableComplete tbody tr:nth-child(even) {
    background-color: rgba($color: $greenHl, $alpha: .04) !important;
}

.remarks {
    white-space: break-spaces;
}

.eventBox .eventsTableUpcoming tbody tr:nth-child(even) {
    background-color: rgba($color: $blueHl, $alpha: .04) !important;
}