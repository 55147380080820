@use 'sass:math';
@import "./src/styles/Library";
.up {
    width: 30px;
    height: 30px;
    color: $blueHl;
}

.down {
    width: 30px;
    height: 30px;
    color: $yellowHl;
}

.dash {
    width: 30px;
    height: 30px;
    color: $dark1;
}