@mixin radioButtonChecked($value, $color) {

    .form-field-radio-value-#{$value}:hover {
        border-color: $color;
        box-shadow: 0 0 8px rgba($color, .25);
    }

    .form-field-radio-value-#{$value}.checked {
        background-color: $color;
        border-color: $color;
        box-shadow: 0 0 8px rgba($color, .25);
    }

}