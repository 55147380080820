@use 'sass:math';
@import "./src/styles/Library";
.wrap {

    svg {
        overflow: visible;
    }

    .rechart-xAxis text {
        font-size: 2rem;
        padding-top: 20px;
        display: block;
        line-height: 2em;
        transform: translateY(20px);
    }

    .rechart-yAxis text {
        font-size: 2rem;
        padding-top: 20px;
        display: block;
        line-height: 2em;
        transform: translateY(20px);
    }

    :global {

        .x {}

        .recharts-default-legend {
            margin-top: 36px !important;
        }

        .recharts-tooltip-wrapper {
            .recharts-default-tooltip {
                color: $light0;
                border-color: lighten($darkBg, 12%) !important;
                background-color: darken($darkBg, 6%) !important;
                border-radius: 5px;
            }
        }

        .recharts-cartesian-axis-line {
            stroke: transparent;
        }

        .recharts-line {
            width: 100%;
        }

        //    .rechart-cartesian-grid line {
        //         stroke:#fff;
        //         stroke-opacity: .2;
        //     }
        //    .rechart-yAxis {
        //         stroke:#fff;
        //         stroke-opacity: .2;
        //     }
        //    .rechart-xAxis {
        //         stroke:#fff;
        //         stroke-opacity: .2;
        //     }

    }

}