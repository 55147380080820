@use 'sass:math';
@import "./src/styles/Library";
.table {

}

.table :global {

    .meta-table dt {
        color: $primary;
        font-weight: 600;
        font-size: 1rem;
    }

    .meta-table dl {
        color: $dark0;
        font-weight: 400;
        font-size: 1rem;
    }

}
