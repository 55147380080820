@use 'sass:math';
@import "./src/styles/Library";
 .container {
     box-shadow: none;
     display: flex;
     flex-direction: row;
     //  align-items: center;
     border: 1px solid $panelBdr;
     border-radius: 20px;
     padding: 1.5rem;

     .left {
         width: 221px;
         height: 251px;
         display: flex;
         justify-content: center;
         align-items: flex-start;
         box-sizing: border-box;
         position: relative;

         .backgroundImage {
             position: absolute;
             height: 100%;
             width: 100%;
             -webkit-transform: scale(1.01);
             -ms-transform: scale(1.01);
             transform: scale(1.01)
         }

         .innerLeft {

             .label {
                 color: white;
                 font-size: 24px;
                 font-weight: 900;
                 text-align: center;
                 padding-bottom: 10px;
                 padding-top: .5rem;
             }

             .circle {
                 height: 150px;
                 width: 150px;
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                 font-weight: 900;

                 .topSemiCircle {
                     text-align: center;
                     vertical-align: bottom;
                     width: 100%;
                     height: 50%;
                     position: relative;

                     .topUnit {
                         font-size: 1rem;
                         position: absolute;
                         bottom: 32px;
                         left: 0;
                         right: 0;
                         margin-left: auto;
                         margin-right: auto;
                         width: 100%;
                         color: red;
                     }

                     .topValue {
                         font-size: 1.6rem;
                         font-weight: 900;
                         position: absolute;
                         bottom: -5px;
                         left: 0;
                         right: 0;
                         margin-left: auto;
                         margin-right: auto;
                         width: 100%;
                         color: red;

                         .valueTo {
                             font-size: 1rem;
                         }

                     }

                 }

                 .bottomSemiCircle {
                     text-align: center;
                     width: 100%;
                     height: 50%;
                     z-index: 1;
                     position: relative;

                     .bottomUnit {
                         font-size: 1rem;
                         color: $greenHl2;
                     }

                     .bottomValue {
                         font-size: 1.6rem;
                         font-weight: 900;
                         color: $greenHl2;

                         .valueTo {
                             font-size: 1rem;
                         }
                     }
                 }

             }

         }
     }

     .middle {
         width: 45%;
         box-shadow: none;
         padding: 0 1.5rem;
         display: flex;
         flex-direction: column;


         .middleTop {
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             align-items: center;
             flex-grow: 1;

             .unit {
                 font-size: 30px;
                 font-weight: 700;
                 color: currentColor;
                 line-height: 1;
             }

             .rating {
                 color: currentColor;
                 font-size: 1.3rem;
                 font-weight: 700;
                 padding-bottom: .5rem;
             }

             .iconContainer {
                 width: 25%;
                 min-width: 100px;
                 max-width: fit-content;

                 .icon {
                     width: 100%;
                     height: 100%;
                 }
             }

         }

         .middleBottom {
             position: relative;
             padding-top: 15px;
             padding-bottom: 35px;

             .description {
                 font-size: 1.2rem;
                 font-weight: 600;
                 color: $dark0;
                 padding-bottom: 10px;
             }

             .bar {
                 display: flex;
                 text-align: center;
                 justify-content: space-between;
                 border-radius: 20px;
                 border: 2px solid $light3;
                 position: relative;
                 height: 32px;

                 .label {
                     font-size: 13px;
                     font-weight: 600;
                     line-height: 0.7rem;
                     color: $dark3;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     height: 32px;
                     position: relative;
                     white-space: nowrap;
                     top: 30px;

                     .ticker {
                         height: 10px;
                         background-color: $light3;
                         position: absolute;
                         width: 2px;
                         top: -2px;
                     }
                 }

                 .label:nth-child(1) {
                     flex-basis: 9%;
                 }

                 .label:nth-child(2) {
                     flex-basis: 27%;
                 }

                 .label:nth-child(3) {
                     flex-basis: 36%;
                 }

                 .label:nth-child(4) {
                     flex-basis: 12%;
                 }

                 .label:nth-child(5) {
                     flex-basis: 12%;
                 }

                 .label:nth-child(6) {
                     flex-basis: 12%;
                 }

             }

             .pill {
                 position: absolute;
                 top: 5px;
                 height: 20px;
                 border-radius: 150px;
                 background-color: currentColor;
             }

         }

     }

     .right {
         width: 30%;
         flex-grow: 1;
         display: flex;
         justify-content: center;
         padding: 0;
         box-shadow: none;

         .innerRight {
             height: 100%;
             width: 100%;
             background-color: $light1;
             border-radius: 8px;

             .rightTop {
                 height: auto;
                 width: auto;
                 padding: 10px 20px;

                 .rightTopLabel {
                     font-size: 1.1rem;
                     font-weight: 700;
                     color: $dark2;
                     padding-bottom: 5px;
                 }

                 .rightTopDescription {
                     color: $dark3;
                 }

             }

             .line {
                 display: flex;
                 justify-content: center;

                 span {
                     display: block;
                     width: 90%;
                     border: 0.5px solid white;
                 }
             }

             .rightBottom {
                 height: auto;
                 width: auto;
                 padding: 10px 20px;

                 .rightBottomLabel {
                     font-size: 1.1rem;
                     font-weight: 700;
                     color: $dark2;
                     padding-bottom: 5px;
                 }

                 .rightBottomDescription {
                     color: $dark3;
                 }
             }
         }

     }

 }

 .zone-1 {
     color: $zone1Light;

     .left {
         background: linear-gradient(180deg, $zone1Light 0%, $zone1Dark 100%);
     }

     .pill {
         left: 9%;
         width: 24%;
     }
 }

 .zone-2 {
     color: $zone2Light;

     .left {
         background: linear-gradient(180deg, $zone2Light 0%, $zone2Dark 100%);
     }

     .pill {
         left: 32%;
         width: 24%;
     }
 }

 .zone-3 {
     color: $zone3Light;

     .left {
         background: linear-gradient(180deg, $zone3Light 0%, $zone3Dark 100%);
     }

     .pill {
         left: 45%;
         width: 24%;
     }
 }

 .zone-4 {
     color: $zone4Dark;

     .left {
         background: linear-gradient(180deg, $zone4Light 0%, $zone4Dark 100%);
     }

     .pill {
         left: 66%;
         width: 12%;
     }
 }

 .zone-5 {
     color: $zone5Light;

     .left {
         background: linear-gradient(180deg, $zone5Light 0%, $zone5Dark 100%);
     }

     .pill {
         left: 77%;
         width: 12%;
     }
 }

 .zone-6 {
     color: $zone6Light;

     .left {
         background: linear-gradient(180deg, $zone6Light 0%, $zone6Dark 100%);
     }

     .pill {
         left: 87%;
         width: 12%;
     }
 }


 @media #{$tablet-portrait-down} {
     .container {
         flex-direction: column;

         .middle {
             width: 100%;
             padding: 2rem 0 3rem;
         }

         .right {
             width: 100%;
         }
     }
 }