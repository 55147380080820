@use 'sass:math';
@import "./src/styles/Library";
.heading {
    position: relative;
    overflow: hidden;
    margin-bottom: 1.2rem;
    text-align: center;
}

.heading :global {
    h2 {
        font-size: 1.55rem;
        font-weight: 600;
        color: $primary;
        display: inline-block;
        position: relative;
    }

    h2:before {
        content: "";
        position: absolute;
        right: 110%;
        top: 1.15rem;
        width: 5rem;
        height: 2px;
        border-radius: 2px;
        background-color: $primaryLight;
    }

    h2:after {
        content: "";
        position: absolute;
        left: 110%;
        top: 1.15rem;
        width: 5rem;
        height: 2px;
        border-radius: 2px;
        background-color: $primaryLight;
    }
}