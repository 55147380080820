@use 'sass:math';
@import "./src/styles/Library";
.iconCalculator {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.table :global {

    .rmwc-data-table__content {

        .rmwc-data-table__body {

            .rmwc-data-table__cell {
                min-width: 10px !important;
            }
        }
    }
}