@use 'sass:math';
@import "./src/styles/Library";
.caption {
    text-align: center;
    font-weight: 700;
}

.imageContainer {
    // width: 375px;
    height: 350px;
    display: flex;
    border: 1px solid $light3;
    padding: 5px;
}

.image {
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    // width: 100%;
    height: 100%;
    object-fit: contain;
}