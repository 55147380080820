@use 'sass:math';
@import "./src/styles/Library";
.arrow {
    display: flex;
    justify-content: center;

    svg {
        width: 50px;
        height: 50px;
    }
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
}

.time {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.5rem;
    text-align: end;

    span {
        font-size: 1rem;
        margin-bottom: 0.3rem;
        padding-left: 0.3rem;
    }
}

.remarks {
    font-size: 1.2rem;
}

.icon {
    width: 25px;
    height: 25px;
}

.iconCheck {
    color: $blueHl;
    width: 25px;
    height: 25px;
}

.swimTitle {
    font-weight: 900;
    color: $blueHl;
    font-size: 1.5rem;
}

.bikeTitle {
    font-weight: 900;
    color: $greenHl;
    font-size: 1.5rem;
}

.runTitle {
    font-weight: 900;
    color: $pinkHl;
    font-size: 1.5rem;
}

.otherTitle {
    font-weight: 900;
    color: $redHl;
    font-size: 1.5rem;
}

.target {
    font-weight: 900;
    color: $blueHl;
}

.blankTarget {
    font-weight: 900;
}

.targetBelow {
    font-weight: 900;
    color: $yellowHl !important;
}

.targetMet {
    font-weight: 900;
    color: $greenHl !important;
}

.targetAbove {
    font-weight: 900;
    color: $blueHl !important;
}

.highlightText {
    font-size: 1.1em;
}

.ratingIcon {
    float: left;
    height: 40px;
    margin-right: 8px;
    object-fit: scale-down;
}