@use 'sass:math';
@import "./src/styles/Library";
.summary {}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.heading {
    flex-basis: 30%;
    text-align: right;
    box-sizing: border-box;
    font-size: 1.2rem;
    font-weight: 600;
    padding-top: 1.6rem;
}

.values {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 70%;
}

.label {
    color: $dark3;
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
}

.item {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 1rem;
    text-align: center;
    flex-basis: 45%;
}