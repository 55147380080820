@use 'sass:math';
@import "./src/styles/Library";
.image {
    width: 100px;
    height: 100px;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.legendContainer {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
        font-size: 1rem;
    }
}

.major {
    width: 10px;
    height: 10px;
    background-color: $redHl;
}

.mild {
    width: 10px;
    height: 10px;
    background-color: $yellowHl;
}

.normal {
    width: 10px;
    height: 10px;
    background-color: $blueHl;
}