@use 'sass:math';
@import "./src/styles/Library";
.list {
    display: flex;
    align-items: center;
    gap: 3px;
    padding-bottom: 5px;
}

.brand {
    font-size: .9rem;
    font-weight: 600;
}

.tick {
    width: 15px;
    height: 15px;
    background-color: $dark3;
    border: 1px solid $dark3;
}

.untick {
    width: 15px;
    height: 15px;
    border: 1px solid $dark3;
}