@use 'sass:math';
@import "./src/styles/Library";
.labelvalue {
    max-width: 160px;
    line-height: 1.2;
}

.label {
    color: $primary;
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
}

.value {
    color: $dark0;
    font-weight: 600;
    font-size: 1.1rem;
    white-space: nowrap;
}

.suffix {
    font-size: 85%;
}

.prefix {
    font-size: 85%;
}