@use 'sass:math';
@import "./src/styles/Library";
.barContainer {
    position: relative;
}

.text {
    text-align: center;
    font-weight: 900;
}

.text-Power,
.text-Speed {
    color: $greenHl;
}

.text-HR {
    color: $redHl2;
}

.zone {
    position: absolute;
    font-weight: 900;
    width: 100%;

    p {
        text-align: center;
    }
}

.zone-0 {
    bottom: 17%;
}

.zone-1 {
    bottom: 35%;
}

.zone-2 {
    bottom: 48%;
}

.zone-3 {
    bottom: 61%;
}

.zone-4 {
    bottom: 74%;
}

.zone-5 {
    bottom: 88%;
}