@use 'sass:math';
@import "./src/styles/Library";
.labels {
    display: flex !important;
    justify-content: space-between !important;
}

.labels > span {
    text-align: center;
}

.labels > span:first-child {
    text-align: left;
}
.labels > span:last-child {
    text-align: right;
}