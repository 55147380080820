@use 'sass:math';
@import "./src/styles/Library";
.shoeRating {
    color: $yellowText;
    font-size: 1.5rem;
    font-weight: 700;
}

.severe-pronation {
    color: $redHl;
}

.mild-pronation {
    color: $yellowHl;
}

.normal {
    color: $blueHl;
}

.mild-supination {
    color: $yellowHl;
}

.severe-supination {
    color: $redHl;
}

.containerLegend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    p {
        font-weight: 600;
    }

    .textNone {
        color: $grayH1
    }

    .boxNone {
        background: $grayH1;
        width: 13px;
        height: 13px;
    }

    .textMild {
        color: $yellowHl
    }

    .boxMild {
        background: $yellowHl;
        width: 13px;
        height: 13px;
    }

    .textSevere {
        color: $redHl
    }

    .boxSevere {
        background: $redHl;
        width: 13px;
        height: 13px;
    }

}