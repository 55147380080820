@use 'sass:math';
@import "./src/styles/Library";
.page {
    min-height: 1090px;
    page-break-before: always;
    page-break-after: always;
    border-radius: 7px;
    border-radius: 0px;
    box-shadow: none !important;
}

.body {
    padding: 20px 20px;
}

.heading {
    background: $lightBlue;
    background: linear-gradient(270deg, $lightBlue 0%, $medBlue 49%, $darkBlue 100%);
    border-bottom: 1px solid $panelBdr;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
}

.heading h1 {
    font-size: 2.5rem;
    font-weight: 900;
    color: $primaryLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading p {
    font-size: 1.5rem;
    font-weight: 900;
    color: $primaryLight;
    display: flex;
    justify-content: flex-end;
}