@use 'sass:math';
@import "./src/styles/Library";
.title {
    margin: -10px 0 0 10px;
    text-align: left;
    color: $secondary;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: 14px;
    white-space: nowrap;
}

.exercisesContainer {
    border-left: 2px solid $secondary;
}

.image {
    height: 160px;
    width: auto;
    object-fit: contain;

    img {
        background-color: $light0 !important;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}