@use 'sass:math';
@import "./src/styles/Library";
.heading {
    font-weight: 900;
    width: fit-content;
}

.equipmentImage {
    width: 70px;
    height: 70px;
    object-fit: scale-down;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
}