$fieldBg : $light1 !default;
$fieldBdr : $light2 !default;
$fieldText : $bodyText !default;

$fieldFocusBg : $light0 !default;
$fieldFocusBdr : $primary !default;
$fieldFocusText : $headingText !default;

$manualBg: #fff7e8;
$manualBdr: darken($manualBg, 40%);
$greenBg :#f5fcf2;
$greenBdr: darken($greenBg, 40%);
$blueBg :#f3f9ff;
$blueBdr: darken($blueBg, 40%);

// .form .form-field.manual-input {

//     .form-field-text,
//     .form-field-select__control,
//     .form-field-phone .form-control {
//         background: $manualBg;
//         border-color: $manualBdr;
//     }

//     .form-field-text.focus,
//     .form-field-text:focus,
//     .form-field-select__control--menu-is-open,
//     .form-field-select__control--is-focused,
//     .form-field-select__control--is-focused:hover,
//     .form-field-phone .form-control:focus {
//         background: $fieldFocusBg;
//         border-color: $fieldFocusBdr;
//         color: $fieldFocusText;
//         box-shadow: 0 0 8px rgba($fieldFocusBdr, .2);
//     }

// }

.form-field.manual-input {

    .form-field-text,
    .form-field-select__control,
    .form-field-phone .form-control {
        background: $manualBg;
        border-color: $manualBdr;
    }

    .form-field-text.focus,
    .form-field-text:focus,
    .form-field-select__control--menu-is-open,
    .form-field-select__control--is-focused,
    .form-field-select__control--is-focused:hover,
    .form-field-phone .form-control:focus {
        background: $fieldFocusBg;
        border-color: $fieldFocusBdr;
        color: $fieldFocusText;
        box-shadow: 0 0 8px rgba($fieldFocusBdr, .2);
    }

}

.form .form-field.form-field-disabled {

    .form-field-text,
    .form-field-select__control,
    .form-field-phone .form-control {
        background: $fieldBg;
        border-color: $fieldBg;
    }

}

.form .form-field.checkbox-with-image {

    .form-field-checkbox {
        flex-wrap: nowrap;
        width: calc(100% + 8px);
    }

    .form-field-checkbox-button label {
        height: auto;
        margin: 0;
        padding: 8px;
    }

    .form-field-checkbox-button .label-image {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 0.5rem;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: .5rem;
        min-height: 6rem;
    }

    .form-field-checkbox-button .label-image img {
        width: 100%;
    }

    .form-field-checkbox-button .label-text {
        text-align: center;
    }

    .form-field-checkbox-button .mdc-form-field .mdc-checkbox {
        display: none;
    }

}

.form .form-field.checkbox-with-image-wrap {

    .form-field-checkbox {
        flex-wrap: wrap;
    }

}

.form-not-editable {

    .form-field {
        cursor: not-allowed !important;
    }

    .form-field-inner {
        pointer-events: none !important;
    }

    .form-field-type-list {
        cursor: default !important;

        .form-field-inner {
            pointer-events: all !important;
        }

        .form-field-list>.btn {
            display: none;
        }
    }

    .form-field-text {
        border-color: $fieldBg !important;
    }

    .form-field-select__control {
        border-color: $fieldBg !important;
    }

    .form-field-select__control {
        border-color: $fieldBg !important;
    }

    .form-field-select__indicators {
        visibility: hidden !important;
    }

    .form-field .form-field-radio-button .mdc-form-field,
    .form-field .form-field-checkbox-button .mdc-form-field {
        border-color: $fieldBg !important;
    }

}

.form-field.green-input {

    .form-field-text,
    .form-field-select__control,
    .form-field-phone .form-control {
        background: $greenBg;
        // border-color: $greenBdr;
    }

    .form-field-text.focus,
    .form-field-text:focus,
    .form-field-select__control--menu-is-open,
    .form-field-select__control--is-focused,
    .form-field-select__control--is-focused:hover,
    .form-field-phone .form-control:focus {
        background: $fieldFocusBg;
        border-color: $fieldFocusBdr;
        color: $fieldFocusText;
        box-shadow: 0 0 8px rgba($fieldFocusBdr, .2);
    }

}

.form-field.blue-input {

    .form-field-text,
    .form-field-select__control,
    .form-field-phone .form-control {
        background: $blueBg;
        // border-color: $blueBdr;
    }

    .form-field-text.focus,
    .form-field-text:focus,
    .form-field-select__control--menu-is-open,
    .form-field-select__control--is-focused,
    .form-field-select__control--is-focused:hover,
    .form-field-phone .form-control:focus {
        background: $fieldFocusBg;
        border-color: $fieldFocusBdr;
        color: $fieldFocusText;
        box-shadow: 0 0 8px rgba($fieldFocusBdr, .2);
    }

}

.form-field.small {
    padding: 0;
}

html .form-field .form-field-radio-button {

    text-align: center;

    @include radioButtonChecked("good", $greenHl);
    @include radioButtonChecked("adequate", $orangeHl);
    @include radioButtonChecked("restricted", $redHl);
    @include radioButtonChecked("not-assesed", $grayH1);

    @include radioButtonChecked("even", $greenHl);

    @include radioButtonChecked("right-shorter", $redHl);
    @include radioButtonChecked("right-forward", $redHl);
    @include radioButtonChecked("right-wider", $redHl);
    @include radioButtonChecked("right-lower", $redHl);

    @include radioButtonChecked("left-shorter", $redHl);
    @include radioButtonChecked("left-forward", $redHl);
    @include radioButtonChecked("left-wider", $redHl);
    @include radioButtonChecked("left-lower", $redHl);

    @include radioButtonChecked("uncertain", $grayH1);
    @include radioButtonChecked("skipped", $grayH1);

    @include radioButtonChecked("no-effect", $redHl);
    @include radioButtonChecked("partial-effect", $orangeHl);
    @include radioButtonChecked("full-effect", $greenHl);

    @include radioButtonChecked("neutral", $greenHl);

    @include radioButtonChecked("turned-outwards", $orangeHl);
    @include radioButtonChecked("rolled-outwards", $orangeHl);

    @include radioButtonChecked("turned-inwards", $redHl);
    @include radioButtonChecked("rolled-inwards", $redHl);

    @include radioButtonChecked("stable", $greenHl);
    @include radioButtonChecked("some-instability", $orangeHl);
    @include radioButtonChecked("lots-of-instability", $redHl);

    @include radioButtonChecked("equal", $greenHl);

    @include radioButtonChecked("very-good", $greenHl);
    @include radioButtonChecked("good", $blueHl);
    @include radioButtonChecked("fair", $purpleHl);
    @include radioButtonChecked("weak", $orangeHl);
    @include radioButtonChecked("very-weak", $redHl);

    @include radioButtonChecked("narrow", $blueHl);
    @include radioButtonChecked("normal", $greenHl);
    @include radioButtonChecked("wide", $orangeHl);
    @include radioButtonChecked("very-wide", $redHl);

    @include radioButtonChecked("fair", $goldHl);

    @include radioButtonChecked("major-issue", $redHl);
    @include radioButtonChecked("mild-issue", $yellowHl);
    @include radioButtonChecked("normal-optimal", $blueHl);

}

html .form-field.form-field-radio-issue-none {
    .mdc-form-field.checked {
        background-color: $light4;
        border-color: $light4;
        box-shadow: 0 0 8px rgba($light4, .25);
    }
}

html .form-field.form-field-radio-issue-normal {
    .mdc-form-field.checked {
        background-color: $blueHl;
        border-color: $blueHl;
        box-shadow: 0 0 8px rgba($blueHl, .25);
    }
}

html .form-field.form-field-radio-issue-mild {
    .mdc-form-field.checked {
        background-color: $yellowHl;
        border-color: $yellowHl;
        box-shadow: 0 0 8px rgba($yellowHl, .25);
    }
}

html .form-field.form-field-radio-issue-major {
    .mdc-form-field.checked {
        background-color: $redHl;
        border-color: $redHl;
        box-shadow: 0 0 8px rgba($redHl, .25);
    }
}

.form-field.text-75 {

    span {
        font-size: .75rem !important;
    }

}