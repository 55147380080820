@use 'sass:math';
@import "./src/styles/Library";
.fieldWrap {
    margin: 10px 0 -10px;
}

.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }
}

.demographic {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2;
    color: $dark4;
    margin-top: 1em;
}

.inner {
    padding: 0 10px;
    overflow: hidden;
}

.ranking {
    display: flex;
    padding: 10px 0;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
}

.valueContainer {
    flex-basis: 65%;
    line-height: 1em;
    font-weight: 900;
    text-align: right;

    .measurement {
        font-size: 2.4em;
        display: block;
        color: $secondary;

        .unit {
            color: $greenHl2;
            font-size: .4em;
            font-weight: 600;
            line-height: 1em;
        }
    }

    .altSep {
        color: $light5;
        font-size: .4em;
        font-weight: 400;
    }

    .prefix {
        color: $zone3Light;
        font-size: .9em;
        font-weight: 900;
    }

}

.rating {
    color: $secondary;
}

.edit {
    margin-right: -4px;
    left: -6px;
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: $dark5;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: $primary;
        color: $light0;
    }
}

.heading {
    font-size: 1.25em;
    flex-basis: 35%;
    font-weight: 900;
    line-height: 1.2em;
    white-space: nowrap;
    padding-left: 12px;
}

.metric {
    font-size: 1.15em;
    flex-basis: 35%;
    font-weight: 900;
    line-height: 1.2em;
    white-space: nowrap;
}

.rankingAlt {

    .measurement {
        color: $blueHl;

        .unit {
            color: $primary;
        }
    }

    .ratingValue {
        color: $blueHl;
    }
}


.arrow {
    position: absolute;
    width: 2px;
    height: 32px;
    color: $secondary;
    transform: translate(-50%, -100%);
    background-color: $light0;
    box-shadow: 0 0 3px rgba($dark0, .1);
}

.arrow:after {
    content: "";
    position: absolute;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background-color: currentColor;
    border: 2px solid $light0;
    box-shadow: 0 0 3px rgba($dark0, .06);
    transform: translate(-50%, -50%);
}

.arrowAlt {
    color: $blueHl;
}

.arrowAlt:after {
    top: 32px;
}

.arrowDashed {
    height: 70px;
    background-color: transparent;
    box-shadow: none;
    border-left: 2px dashed rgba($dark2, 60%);
    width: 0px;
    transform: translate(-50%, -72%);
}

.arrowDashed:after {
    display: none;
}

.scale {
    font-size: 13px;

    .bottom {
        position: relative;

        .colorBar {
            display: flex;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            text-align: center;

            span {
                font-size: 1em;
                font-weight: 600;
                line-height: 0.7em;
                color: $light0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 1px;
                text-shadow: 1px 1px 2px rgba($dark4, 20%);
                position: relative;
                white-space: nowrap;
            }

            span:first-child i {
                left: 8px;
            }

            span:last-child i {
                right: 8px;
            }

            .hidden {
                font-size: 0px;
            }

            i {
                position: absolute;
                font-style: normal;
            }
        }

        .rangesBar {
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            height: 1.75em;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1px;
                height: 1.5em;
                position: absolute;
                white-space: nowrap;
            }

            span:first-child {
                justify-content: flex-start;
            }

            span:last-child {
                justify-content: flex-end;
            }

        }

        i {
            position: absolute;
            font-style: normal;
        }
    }

}

.steps,
.levels {
    font-size: 13px;

    .bottom {
        position: relative;

        .colorBar {
            display: flex;
            flex-wrap: nowrap;
            display: flex;
            justify-content: space-between;
            text-align: center;

            span {
                font-weight: 600;
                line-height: 0.7em;
                color: $light0;
                width: 16.6666666667%;
                flex: 1;
                text-shadow: 1px 1px 2px rgba($dark4, 20%);
                height: 28px;
                word-wrap: break-word;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

        }


        .rangesBar {
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            height: 1.75em;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1px;
                height: 1.5em;
                position: absolute;
                white-space: nowrap;
            }

            span:first-child {
                justify-content: flex-start;
            }

            span:last-child {
                justify-content: flex-end;
            }

        }

        i {
            font-style: normal;
        }

    }
}

.levels {

    .bottom {

        .colorBar span {
            width: auto !important;
            flex-basis: 100% !important;
        }

        .rangesBar span {
            position: static !important;
        }

    }

}

.scale .bottom .colorBar {

    background: linear-gradient(90deg,
            $purpleHl 0%,
            $greenHl 50%,
            $orangeHl 100%);

}

.scale .bottom .centeredColor {

    background: linear-gradient(90deg,
            $orangeHl 0%,
            $greenHl 25%,
            $purpleHl 50%,
            $greenHl 75%,
            $orangeHl 100%);

}

// .steps .bottom .colorBar {

//     span:nth-child(1n) {
//         background: $purpleHl;
//     }

//     span:nth-child(2n) {
//         background: $pinkHl;
//     }

//     span:nth-child(3n) {
//         background: $blueHl;
//     }

//     span:nth-child(4n) {
//         background: $greenHl;
//     }

//     span:nth-child(5n) {
//         background: $orangeHl;
//     }

//     span:nth-child(6n) {
//         background: $yellowHl;
//     }

//     span:nth-child(7n) {
//         background: $redHl;
//     }

// }

.steps .bottom .colorBar,
.levels .bottom .colorBar {

    span:nth-child(1n) {
        background: $blueHl;
    }

    span:nth-child(2n) {
        background: $greenHl;
    }

    span:nth-child(3n) {
        background: $yellowHl;
    }

    span:nth-child(4n) {
        background: $orangeHl;
    }

    span:nth-child(5n) {
        background: $redHl;
    }

    span:nth-child(6n) {
        background: $pinkHl
    }

    span:nth-child(7n) {
        background: $purpleHl;
    }

}

.panelEvent :global .colorBar span {
    flex-basis: 33% !important;
}

.panelEvent :global .arrow+.rangesBar {
    display: none !important;
}