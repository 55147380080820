@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-body {
        background: $panelBg;
        border-radius: 11px;

    }

}

.heading {
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
}

.text {
    font-weight: 600;
    font-size: .9rem;

    span {
        font-size: 2rem;
        color: $greenHl;
    }
}

.items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}

.item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 300px;
    text-align: center;
    padding-bottom: 1rem;

    .graphic {
        height: 100%;
        width: 56px;
        margin: 0 auto;
        border-radius: 5px;
    }

    .Whole {
        background-color: $orangeHl;
    }

    .Upper {
        background-color: $greenHl;
    }

    .Lower {
        background-color: $blueHl;
    }

    .WholeValue {
        font-size: 1.3rem;
        font-weight: 900;
        color: $orangeHl;
    }

    .UpperValue {
        font-size: 1.3rem;
        font-weight: 900;
        color: $greenHl;
    }

    .LowerValue {
        font-size: 1.3rem;
        font-weight: 900;
        color: $blueHl;
    }


}