@use 'sass:math';
@import "./src/styles/Library";
.arrow {
    color: $redHl;
    width: 30px;
    height: 30px;
}

.check {
    color: $greenHl;
    width: 30px;
    height: 30px;
}

.key,
.value {
    font-weight: 900;
}

.value {
    font-size: 2rem
}

.Uncertain {
    @extend .value;
}

.Very-Likely,
.Likely,
.Possible {
    @extend .value;
    color: $greenHl
}

.Very-Unlikely,
.Unlikely {
    @extend .value;
    color: $redHl
}

.Very-Likely,
.Very-Unlikely {
    font-size: 2.3rem;
}


.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-body {
        background: $panelBg;
        border-radius: 11px;

    }
}

.chartPanel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;
    margin: .5rem 0;

    .chartPanel-body {
        background: $panelBg;
        border-radius: 11px;
    }
}

.level {
    @extend .value;
    color: $greenHl;
    text-align: center;
}