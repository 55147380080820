@use 'sass:math';
@import "./src/styles/Library";
.label {
    display: flex;
    justify-content: space-between;

    .top {
        color: $secondary;
        font-weight: 700;
    }

    .blackTop {
        color: $dark3;
        font-weight: 700;
    }

    .bottom {
        color: $primary;
        font-weight: 700;
    }
}

.major {
    width: 15px;
    height: 15px;
    background-color: $redHl;
}

.mild {
    width: 15px;
    height: 15px;
    background-color: $yellowHl;
}

.normal {
    width: 15px;
    height: 15px;
    background-color: $blueHl;
}

.box {
    height: 100%;
}