@use 'sass:math';
@import "./src/styles/Library";
.row :global {
    padding: 0 .7em;

    .rangesBar {
        visibility: hidden;
        margin-top: -4px;
        height: 0;
    }
}

.measurement {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    padding-bottom: 4px;
    color: $secondary;
}

.displayShow {
    margin: -.6rem -8px 0;
    padding: 4px 8px 0;
    flex: 1;
}