@use 'sass:math';
@import "./src/styles/Library";
.legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    p {
        color: $dark0;
    }
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 500px;
    background: $darkBg;
    z-index: 1;
    border: 2px solid $light0;
}

.dashed {
    border: 1px dashed $darkBg;
    width: 1.2rem;
}