@use 'sass:math';
@import "./src/styles/Library";
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textParent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.met {
    @extend.textParent;
    color: $secondary;
    font-weight: 700;

    .label {
        color: $dark0;
        width: 65%;
    }

    .dash {
        color: $dark0;
        text-align: start;
        width: 5%;
    }

    .value {
        width: 10%;
    }

    svg {
        width: 25px;
        height: 25px;
    }
}

.above {
    @extend.textParent;
    color: $blueHl;
    font-weight: 700;

    .label {
        color: $dark0;
        width: 65%;
    }

    .dash {
        color: $dark0;
        text-align: start;
        width: 5%;
    }

    .value {
        width: 10%;
    }

    svg {
        width: 25px;
        height: 25px;
    }
}

.below {
    @extend.textParent;
    color: $yellowHl;
    font-weight: 700;

    .label {
        color: $dark0;
        width: 65%;
    }

    .dash {
        color: $dark0;
        text-align: start;
        width: 5%;
    }

    .value {
        width: 10%;
    }

    svg {
        width: 25px;
        height: 25px;
    }
}

.subText {
    color: $grayH1;
}

.all {
    color: $redHl;
    font-weight: 600;

    span {
        color: $grayH1;
    }
}

.bike {
    color: $secondary;
    font-weight: 600;

    span {
        color: $grayH1;
    }
}

.run {
    color: $purpleHl3;
    font-weight: 600;

    span {
        color: $grayH1;
    }
}

.yellow {
    color: $yellowHl;

    span {
        color: $grayH1;
    }
}

.blue {
    color: $blueHl;
}

.green {
    color: $secondary;

    span {
        color: $grayH1;
    }
}

.noRating {}

.gray {
    color: $grayH1;
}

.red {
    color: $redHl;
}

.pink {
    color: $pinkHl;

    span {
        color: $grayH1;
    }
}

.info {
    justify-content: flex-end;
    display: flex;
}

.header {
    color: $primary;
}