@use 'sass:math';
@import "./src/styles/Library";
 .panel {
}

.panel :global .panel-tabs {
    background-color: rgba($light1, .75);

    .mdc-tab .mdc-tab__text-label {
        color: $dark2;
        font-weight: 600;
    }
    .mdc-tab--active .mdc-tab__text-label {
        color: $primary;
    }
}