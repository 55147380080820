@use 'sass:math';
@import "./src/styles/Library";
.card {
    display: flex;
    align-items: center;
    background-color: $light1;
    border-radius: 4px;
    padding: 8px;
}

 .label {
    font-size: 1.1rem;
    flex-grow: 1;
    padding: 0 8px;
}