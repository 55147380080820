@use 'sass:math';
@import "./src/styles/Library";
.list {
    display: flex;
    align-items: center;
    gap: 3px;
    padding-bottom: 5px;
}

.chartContainer {
    width: 100%;

    .barChart {
        width: 100%;
        position: relative;

        table {
            border-bottom: 1px solid $light2;
        }

        table tr {
            border-top: 1px solid $light2;
        }
    }

    .mileage-bar {
        width: 30px;
        background-color: $secondary;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 0;

    }

    .elite {
        @extend .mileage-bar;
        height: 133px;
    }

    .very-high {
        @extend .mileage-bar;
        height: 114px;
    }

    .high {
        @extend .mileage-bar;
        height: 95px;
    }

    .moderately-high {
        @extend .mileage-bar;
        height: 76px;
    }

    .moderate {
        @extend .mileage-bar;
        height: 57px;
    }

    .low {
        @extend .mileage-bar;
        height: 38px;
    }

    .very-low {
        @extend .mileage-bar;
        height: 19px;
    }

    .none {
        @extend .mileage-bar;
        height: 0px;
    }

    .label {
        color: #326EB6;
        text-align: center;
        width: 100%;
    }

    table {
        color: #939393;
        font-weight: 500;
        font-size: 12px;
        width: 100%;
    }

    table td,
    th {
        text-align: left;
        padding-left: 5px;
        padding-right: 5px;
        vertical-align: middle;
        height: 19px;
    }

    table tr:nth-child(odd) {
        background: #f4f4f4;
    }

    table tr:nth-child(even) {
        background: white
    }

    table td:nth-child(1) {
        text-align: left;
    }

    table td:nth-child(2) {
        text-align: right;
    }
}

.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        background: transparent;
    }
}

.positionImage {
    border-radius: 1rem !important;
    border: 1px solid $dark3;
    background-color: transparent;
}

.icon {
    width: 200px;
    object-fit: contain;
}

.text-label {
    font-weight: 600;
    font-size: 13px;
}

.caption-label {
    color: $blueHl;
}

.aero {
    color: $purpleHl3;
}

.power {
    color: $secondary;
}

.speed {
    color: $blueHl;
}

.positionImage {
    border-radius: 1rem !important;
    border: 1px solid $dark3;
    background-color: transparent;
}

.positionTable {
    border-right: 1px solid $light2;
}

.text-blue {
    color: $blueText;
}

.text-light-blue {
    color: $lightBlueText;
}

.text-secondary {
    color: $secondary;
}

.text-purple {
    color: $purpleHl3;
}

.text-dark4 {
    color: $dark4;
}