@use 'sass:math';
@import "./src/styles/Library";
 .panel {
     background: $darkBg;
     border-radius: 11px;
     box-shadow: none;
     color: $panelBg;
     overflow: hidden;
 }

 .panel :global {

     h2 {
         color: $panelBg;
         line-height: 1.9;
     }

     h3 {
         color: $panelBg;
         line-height: 1.9;
         font-size: 1.2rem;
     }

     .chart-formula {
         margin-bottom: -10px;
         display: block;
     }

    .form-field {
        border-radius: 5px;
        padding: 0 8px;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        background-color: rgba($dark0, .1);
    }

    .form-field-label  {
        padding: 10px 0 0;
        color: rgba($light0, 1);
    }

    .form-field-label-required  {
        display: none;
    }

    .form-field .mdc-form-field {
        color: rgba($light0, .7);
    }
    .form-field .mdc-form-field.checked {
        color: rgba($light0, 1);
    }

    .form-field .mdc-form-field>label {
        color: currentColor;
        cursor: pointer;
    }


    .form-field .mdc-form-field span {
        display: inline-block;
        margin-left: -3px;
    }

    .form-field .mdc-form-field.checked>label {
        color: currentColor;
    }

    .form-field .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .form-field .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
        background-color: currentColor;
        border-color: currentColor;
    }
    .form-field  .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
        color: $dark1;
    }

    .form-field .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
        border-color: currentColor;
    }
   .form-field .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
        border-color: currentColor;
    }

    .form-field .mdc-form-field {

        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)~.mdc-checkbox__background {
            border-color: currentColor;
        }

        .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
            border-color: currentColor;
        }

    }

}