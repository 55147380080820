@use 'sass:math';
@import "./src/styles/Library";
.up {
    width: 30px;
    height: 30px;
    color: $blueHl;
}

.down {
    width: 30px;
    height: 30px;
    color: $yellowHl;
}

.question {
    width: 30px;
    height: 30px;
    color: $redHl;
}

.dash {
    width: 30px;
    height: 30px;

}

.legend {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
}