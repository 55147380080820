@use 'sass:math';
@import "./src/styles/Library";
.measuresRow :global {
    .value {
        font-size: 1.1em;
    }
    .value small {
        display: block;
        font-size: .88em;
    }
}