@use 'sass:math';
@import "./src/styles/Library";
.bar {
    background: rgba($light1, 50%);
    margin: -20px -20px 12px -20px;
    padding: 12px 20px;
    display: block;
    width: 100%;
    border-bottom: 1.5px solid $primaryLight;
}

.name {
    font-weight: 600;
    color: $blueHl;
}

.dateValue {
    font-weight: 600;
}