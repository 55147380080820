@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-heading {
        padding-bottom: var(--spacing-auto);
    }

    .form-field {
        width: 180px;
        padding: 0;
        margin: -10px 0;
    }

}

.innerAthlete {
    border-top: 1px solid $panelBdr;
    border-bottom: 1px solid $panelBdr;
    background: $panelFooterBg;
    padding: var(--spacing-small) var(--spacing-auto);

    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .5rem 0;
    }

    .name {
        font-size: 1.1rem;
        font-weight: 700;
        flex-basis: 40%;
    }

    .center {
        border: 1px dashed $darkBg;
        height: 130%;
        margin-top: -2%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .chart {
        width: 100%;
        height: 2.5rem;
        display: flex;
        position: relative;
    }

    .bar {
        width: 8px;
        height: 100%;
        max-width: 100%;
        transition: 1s ease all;
    }

    .label {
        font-weight: 700;
        font-size: 1.1rem;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        opacity: .9;
        z-index: 3;
        padding: 0px 8px 0;
        transform: translateY(-50%);
        text-shadow: 2px 2px 1px rgba($light0, .25);
    }

    .row {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .chart-nochange {
        .row {
            left: 50%;
            justify-content: flex-start;
        }

        .label {
            left: 0;
        }

        .bar {
            width: 8px !important;
            background: $darkBg;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .chart-improved {
        .row {
            left: 50%;
            justify-content: flex-start;
        }

        .label {
            left: 0;
        }

        .bar {
            background: $greenHl;
            left: 50%;
            animation-name: slideRight;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
        }
    }

    .chart-regressed {
        .row {
            justify-content: flex-start;
            flex-direction: row-reverse;
        }

        .label {
            right: 0;
        }

        .bar {
            background: $redHl;
            right: 50%;
            animation-name: slideLeft;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
        }
    }

    .chart-nodata {
        .row {
            left: 50%;
            justify-content: flex-start;
        }

        .bar {
            width: 8px !important;
            background: $light4;
            left: 50%;
            transform: translateX(-50%);
        }

        .label {
            display: none;
        }
    }

}

.innerTeam {
    padding: var(--spacing-auto);
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    .metric {
        font-size: 1.25em;
        flex-basis: 35%;
        font-weight: 900;
        line-height: 1.2em;
        white-space: nowrap;
    }

    .valueContainer {
        line-height: 1em;
        font-weight: 900;
        text-align: right;
        font-size: 2.4em;
        display: block;
        color: $secondary;

        .sep {
            font-weight: 400;
            color: $light2;
        }

        .unit {
            // color: $greenHl2;
            font-size: .5em;
            line-height: 1em;
        }
    }

    .rating {
        color: $secondary;
        font-size: 1.5em;
    }

    .no-change {
        color: $dark3;
    }

    .Worsened {
        color: $redHl;
    }

}

.legend {
    padding: 1rem;
}

.base {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 1.25rem;
    font-weight: 700;

    div {
        width: 20px;
        height: 10px;
    }
}

.improved {
    @extend .base;
    color: $secondary;

    div {
        background: $secondary;
    }
}

.no-change {
    @extend .base;
    color: $dark3;

    div {
        background: $dark3;
    }
}

.worsened {
    @extend .base;
    color: $redHl;

    div {
        background: $redHl;
    }
}

.underText {
    color: $dark3;
    font-weight: 500;
}

.listName {
    border: .5px solid $light4;
    color: $dark4;
}