@use 'sass:math';
@import "./src/styles/Library";
.block {
    border: 1px solid $panelBdr;
    border-bottom-width: 2px;
}

.currentWeekDot {
    background-color: rgba($color: $redHl, $alpha: 1);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 6px;
}

.currentWeek {
    background-color: rgba($color: $redHl, $alpha: .06);
    .currentWeekDot {
        margin-left: -14px;
    }
}

.legend {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    padding: 10px 10px 10px 24px;
    @extend .currentWeek;
}
