@use 'sass:math';
@import "./src/styles/Library";
.fancy {
    text-align: center;
    font-size: 2.4rem;
}

.value {
    color: $secondary;
    font-weight: 900;
}

.suffix {
    color: $greenHl2;
    font-size: .4em;
    font-weight: 600;
}