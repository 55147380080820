@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }
}

.inner {
    padding: 2rem 2rem;
}


.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;
}

.header {
    font-size: 21px;
    font-weight: 900;
    line-height: 1.2em;
}

.score {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 1rem;
}

.value {
    font-size: 2.6rem;
    font-weight: 900;
    color: $secondary;
    height: 3.2rem;
}

.units {
    font-size: .65em;
    color: $secondaryDark;
}

.source {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $dark4;
    margin-top: 1em;
    margin-bottom: -.5em;
}

.ratingContainer {

    .rating {
        flex-grow: 1;
    }

    .altScores {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        gap: 3rem;
        padding-right: 1.5rem;

        .options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: right;
            gap: .5rem;

            .optionsItem {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                line-height: 1;
            }

            .optionsItemLabel {
                font-weight: 700;
                font-size: 1rem;
                padding-bottom: .4rem;
            }

            .optionsItemValue {
                font-size: 1.8rem;
                color: $secondary;
                min-width: 90px;
                font-weight: 900;
            }

        }

    }
}


.rankingContainer {
    margin: 2rem -2rem;
    padding: 2rem 2rem 3rem;
    overflow: hidden;
    background: $panelFooterBg;
    border-bottom: 1px solid $panelBdr;
    border-top: 1px solid $panelBdr;

    .value {
        font-size: 35px;
        font-weight: 900;
        color: $secondary;

        span {
            font-size: 20px;
            color: $secondaryDark;
        }

        .indicator {
            font-size: 14px;
            color: $secondaryDark !important;
            vertical-align: text-top;
        }
    }


}

@media #{$tablet-portrait-down} {


    .ratingContainer {

        .topContainer {
            flex-wrap: wrap;
        }

        .altScores {
            order:3;
            flex-basis: 100%;
            padding-right: 0;
            padding-top: 1.2rem;
            justify-content: flex-end;
        }

    }

}