@use 'sass:math';
@import "./src/styles/Library";
  .container :global {

      .graphicContainer {
          flex: 1;
          height: 200px;
      }

      .graphic {
          height: 100%;
          width: 60px;
          margin: 0 auto;
          border-radius: 5px;
      }

      .column {
          background-color: $yellowHl;
          @extend .graphic
      }

      .title {
          color: $yellowHl;
          text-align: center;
      }

      .column-cycling,
      .column-fluid-intake {
          background-color: $blueHl;
          @extend .graphic
      }

      .title-cycling,
      .title-fluid-intake {
          color: $blueHl;
          text-align: center;
      }

      .column-running,
      .column-gut-limit {
          background-color: $orangeHl;
          @extend .graphic
      }

      .title-running,
      .title-gut-limit {
          color: $orangeHl;
          text-align: center;
      }

      .column-average,
      .column-rehydration {
          background-color: $greenHl;
          @extend .graphic
      }

      .title-average,
      .title-rehydration {
          color: $greenHl;
          text-align: center;
      }

      .column-fluid-loss {
          background-color: $pinkHl;
          @extend .graphic
      }

      .title-fluid-loss {
          color: $pinkHl;
          text-align: center;
      }

      .table {
          display: flex;
          flex-direction: column;
          text-align: center;
      }

  }

  .heading {
      text-align: center;
      font-size: 1.7rem;
      font-weight: 900;
  }