@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }

    .panel-heading {
        background: $panelBg;
        border-radius: 11px;
    }
}

.head {
    display: flex;
    justify-content: space-between;

    .title {
        font-size: 1.5em;
        padding: 0 0 1em;
        font-weight: 900;
        line-height: 1em;
    }

    .more {
        text-align: right;
        color: $primary;
        font-weight: 600;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
    }

    .more :global svg {
        top: 2px;
        position: relative;
    }
}

.demographic {
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    color: $dark4;
    margin-top: 1em;
    margin-bottom: -.5em;
}

.inner {
    padding: 10px;
}

.fieldWrap {
    margin: 10px 0 -10px;
}

.rows :global {
    margin: 0 -30px;
    overflow: hidden;

    .row:nth-child(odd) {
        background: $panelFooterBg;
    }

}

.row {
    padding: 0 30px;
    border-top: 1px solid $panelBdr;
}

.row:last-child {
    border-bottom: 1px solid $panelBdr;
}

.cols {
    display: flex;
    align-items: center;
    padding: .6em 0 .6em;

    .metric {
        flex-basis: 40%;
        font-size: 1.25em;
        font-weight: 900;
        line-height: 1.2em;
    }

    .measurement {
        flex-basis: 60%;
        font-size: 2.4em;
        font-weight: 900;
        text-align: right;
        color: $secondary;

        .unit {
            color: $greenHl2;
            font-size: .4em;
            font-weight: 600;
        }
    }

    .altSep {
        color: $light5;
        font-size: .65em;
        font-weight: 400;
    }

    .rating {
        font-weight: 900;
        color: $secondary;
    }

}

.displayShow {
    margin: -.6rem -8px 0;
    padding: 4px 8px 1em;
}

.displayHide {
    margin: -.6rem -8px 0;
    height: 4px;
    padding: 8px 8px 0;
    overflow: hidden;

    >div {
        font-size: 0px;
    }

    :global .arrow {
        margin-top: -4px;
    }
}