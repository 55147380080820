@use 'sass:math';
@import "./src/styles/Library";
.desc {
    border: 2px solid $primaryLight;
    padding: 1rem;
    font-size: 1.25em;
}

.no-box {
    padding: 0;
    border: 0;
}

.no-border {
    border: 0;
}

.label {
    font-weight: 600;
}

.h1 {
    font-size: 1.5rem;
}

.value {}