@use 'sass:math';
@import "./src/styles/Library";
/* App */

@import "./Form";

/*
    Project customizations here
    Overides default dash styles
*/

.page-tabs .mdc-tab {
    padding-left: 20px;
    padding-right: 20px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.cleatImage {
    display: inline-flex;
    width: auto;
    height: auto;
    max-height: 160px;
}

.cleatSideImage {
    display: inline-flex;
    width: 100%;
    height: auto;
    max-height: 400px;
}

.footImageForeFoot {
    display: inline-flex;
    max-width: 75%;
    width: 110px;
    height: auto;
    margin-bottom: 30px;
}

.footImagedHeel {
    display: inline-flex;
    max-width: 75%;
    width: 130px;
    height: auto;
    margin-bottom: 30px;
}


.footImageArch {
    display: inline-flex;
    max-width: 75%;
    width: 250px;
    height: auto;
    margin-bottom: 30px;
}

.symmetryImage {
    display: inline-flex;
    max-width: 60%;
    width: 150px;
    height: auto;
}

.stabilityImage {
    display: inline-flex;
    max-width: 100%;
    width: 400px;
    height: auto;
}


.bike-import {
    position: absolute;
    top: 32px;
    right: 80px;
}

.postureImage {
    max-width: 75%;
    width: 400px;
    height: auto;
}


@media #{$laptop-up} {
    .bike-import {
        top: 40px;
        right: 90px;
    }
}

svg .activeRed {
    fill: $redHl !important;
}

svg .activeOrange {
    fill: $orangeHl !important;
}

.row-active {
    background-color: rgba($color: $greenHl, $alpha: .15);
}

.row-avg {
    background-color: rgba($color: $blueHl, $alpha: .15);
}

.row-high {
    background-color: rgba($color: $redHl, $alpha: .06);
}

.row-low {
    background-color: rgba($color: $yellowHl, $alpha: .06);
}

.row-empty {
    background-color: rgba($color: $light1, $alpha: .50);
}

.row-sum td .cell-inner {
    font-weight: bold;
}

.btn-dark {
    color: $light0;
    background-color: rgba($dark0, .24);
    border: 1px solid rgba($dark0, .08);
}

.inline-label {
    display: flex;

    .form-field-label {
        width: 30%;
        padding-bottom: 0;
        padding-right: 12px;
        text-align: right;
        align-self: center;
    }

    .form-field-inner {
        flex-grow: 1;
    }
}

.label-align-left {

    .form-field-label {
        text-align: left;
    }
}

.field-center {
    margin: auto;
}

.table-row-short .rmwc-data-table__cell {
    max-height: 2rem !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.table-bg-none {
    background-color: transparent !important;
}

.table-bg-white {
    background-color: #fff !important;
}

.table-bg-green {
    background-color: rgba($color: $greenHl, $alpha: .06) !important;
}

.table-bg-blue {
    background-color: rgba($color: $blueHl, $alpha: .06) !important;
}

.table-bg-red {
    background-color: rgba($color: $redHl, $alpha: .06) !important;
}

.table-bg-gray {
    background-color: rgba($color: $light1, $alpha: .5) !important;
}

.table-bg-yellow {
    background-color: rgba($color: $yellowHl, $alpha: .06) !important;
}

.table-bg-purple {
    background-color: rgba($color: $purpleHl2, $alpha: .25) !important;
}

.table-bg-brown {
    background-color: rgba($color: $brownHl, $alpha: .06) !important;
}

.table-bg-darkGreen {
    background-color: rgba($color: $greenHl2, $alpha: .06) !important;
}

.table-bg-darkerBlue {
    background-color: rgba($color: $blueHl, $alpha: .4) !important;
}

.table-bg-darkerGreen {
    background-color: rgba($color: $greenHl, $alpha: .4) !important;
}

.table-bg-darkerRed {
    background-color: rgba($color: $redHl, $alpha: .4) !important;
}

.table-bg-darkerYellow {
    background-color: rgba($color: $yellowHl, $alpha: .4) !important;
}

.table-bg-darkerPurple {
    background-color: rgba($color: $purpleHl2, $alpha: .4) !important;
}

.table-highlight {
    background-color: $manualBg !important;
    border-color: $manualBdr !important;
}

.table-bg-zone-1 {
    background-color: rgba($zone1Light, .06);
}

.table-bg-zone-2 {
    background-color: rgba($zone2Light, .06);
}

.table-bg-zone-3 {
    background-color: rgba($zone3Light, .06);
}

.table-bg-zone-4 {
    background-color: rgba($zone4Light, .06);
}

.table-bg-zone-5 {
    background-color: rgba($zone5Light, .06);
}

.table-bg-zone-6 {
    background-color: rgba($zone6Light, .1);
}

.table-bdr-left-gray {
    border-left: 2px solid $light2 !important;
}

.table-bdr-right-gray {
    border-right: 2px solid $light2 !important;
}

.table-bdr-none {
    border: 0px !important;
}

.table-bdr-top-none {
    border-top: 0px !important;
}

.table-bdr-btm-none {
    border-bottom: 0px !important;
}

.table-bdr-left-none {
    border-left: 0px !important;
}

.table-bdr-right-none {
    border-right: 0px !important;
}


.panel-inline {
    box-shadow: none;
    border: 1px solid $panelBdr;
    border-radius: 5px;

    .panel-heading {
        background: $panelFooterBg;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $panelBdr;
    }
}

.cat-color-body-size,
.cat-color-body {
    color: #58B647 !important;
}

.cat-color-speed {
    color: #0085C5 !important;
}

.cat-color-strength {
    color: #FFA621 !important;
}

.cat-color-power {
    color: #FF5722 !important;
}

.cat-color-stamina {
    color: #FFA1C1 !important;
}

.cat-color-suppleness {
    color: #FECF00 !important;
}

.cat-color-skill {
    color: #57E1BB !important;
}

.cat-color-overall {
    color: #6F6AFF !important;
}

.cat-color-,
.cat-color-undefined {
    color: #455078 !important;
}

.rating-color-,
.rating-color-undefined {
    color: #455078 !important;
}

.rating-color-excellent {
    color: $purpleHl !important;
}

.rating-color-very-good {
    color: $blueHl !important;
}

.rating-color-good {
    color: $greenHl !important;
}

.rating-color-average {
    color: $goldHl !important;
}

.rating-color-low {
    color: $orangeHl !important;
}

.rating-color-very-low {
    color: $brownHl !important;
}

.rating-color-poor {
    color: $redHl !important;
}

.coaching-training-filters {
    // max-width: 340px;
    border: 1px solid $panelBdr;
    border-radius: 5px;
    background-color: $panelFooterBg;
    position: relative;
    z-index: 20;
}

.sub-heading-title {
    color: $primary;
    font-weight: 700;
    font-size: 18px;
}

.text-label {
    line-height: 15px;
    color: $primary;
    font-weight: 500;
}

.issue-major {
    background-color: $redHl !important;
    border: 1px solid $redHl !important;
}

.issue-mild {
    background-color: $yellowHl !important;
    border: 1px solid $yellowHl !important;
}

.issue-normal {
    background-color: $blueHl !important;
    border: 1px solid $blueHl !important;
}


#ribbon {

    .zones a {
        height: 50px;
    }

    .zones a i {
        width: 30px;
        height: 30px;
    }

}

.fullscreen-enabled {
    background: $light0;
    padding: 1rem;
    overflow-y: scroll;
}

.full-width {

    .form-field-input-wrap {
        width: 100%;
    }
}

.text-blue {
    color: $blueText;
}

.text-light-blue {
    color: $lightBlueText;
}

.text-secondary {
    color: $secondary;
}

.text-purple {
    color: $purpleHl3;
}

.text-dark4 {
    color: $dark4;
}

.text-75 {

    font-size: 0.75rem !important;
    background-color: red;
}