@use 'sass:math';
@import "./src/styles/Library";
.iconContainer {
    width: 25%;
    min-width: 100px;
    margin-inline: auto;

    .icon {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}

.label {
    text-align: center;
    font-size: 1.55rem;
    font-weight: 600;
    color: $primary;
    margin-top: 1rem;
}

.ratingContainer {
    padding: 1rem 0;
}

.image {
    height: 500px;
}

.labelContainer {
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    .heading {
        color: $primary;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
    }

    .value {
        color: $secondary;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        text-align: center;
    }

    .subValue {
        color: $secondary;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
    }
}