@use 'sass:math';
@import "./src/styles/Library";
.heading {
    background: $lightBlue;
    background: linear-gradient(270deg, $lightBlue 0%, $medBlue 49%, $darkBlue 100%);
    border-bottom: 1px solid $panelBdr;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 3px 3px 0 0;
}

.heading h1 {
    font-size: 2.5rem;
    min-height: 4rem;
    font-weight: 900;
    color: $light0;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.heading h2 {
    font-size: 1.8rem;
    font-weight: 900;
    height: 4rem;
    color: $light0;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -.2rem;
}

.heading h3 {
    font-size: 1.2rem;
    font-weight: 900;
    color: $light0;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: end;
}

.details {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.left,
.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.right {
    justify-content: space-between;
}