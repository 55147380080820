@use 'sass:math';
@import "./src/styles/Library";
  .container :global {

      .panel {
          border-radius: 11px;
          border: 1px solid $panelBdr;
          background: $panelBg;
          box-shadow: 3px 3px 0 0 $light1;
          font-size: 1rem;
          height: 100%;

          .panel-body {
              background: $panelBg;
              border-radius: 11px;
          }
      }

      .description {
          font-size: 1.3rem;

          span {
              color: $primary;
          }
      }

      .peak-hr {
          position: relative;
          display: flex;
          flex-direction: column;

          .heading {
              text-align: center;
              font-weight: 600;
              font-size: 1.3rem;
          }

          p {
              font-size: 15px;
              white-space: nowrap;
          }

          .hr-chart {
              display: flex;
              flex-wrap: wrap;
              height: 330px;

              p {
                  font-weight: 900;
              }

              .graphic {
                  height: 100%;
                  width: 56px;
                  background-color: $redHl;
                  margin: 0 auto;
                  border-radius: 5px;
              }

              .hr-bpm0,
              .columns0 {
                  color: $orangeHl;

                  .graphic {
                      background-color: $orangeHl;
                  }
              }

              .hr-bpm1,
              .columns1 {
                  color: $yellowHl;

                  .graphic {
                      background-color: $yellowHl;
                  }
              }

              .hr-bpm2,
              .columns2 {
                  color: $blueHl;

                  .graphic {
                      background-color: $blueHl;
                  }
              }

              .hr-bpm3,
              .columns3 {
                  color: $redHl;

              }

              .hr-title {
                  width: 50px;
                  white-space: normal;
                  text-align: center;
              }

              .lastHr {
                  margin-left: 3rem;
                  flex: 1;
              }

              .today-hr {
                  flex: 1;
              }

          }

          .checkmarkImage {
              width: 20px;
              height: 20px;
          }

          .checkmarkContainer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              p {
                  white-space: initial;
                  text-align: center;
              }
          }

          .checkmark {
              gap: .3rem;
              color: $greenText;
              font-weight: 600;
          }
      }
  }

  .diffContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: $grayH1;

      span {
          color: $greenText;
          font-size: 2rem;
          font-weight: 900;
      }
  }