@use 'sass:math';
@import "./src/styles/Library";
.check {
    font-size: 2rem;
    color: $greenHl;
}

.totalTest {
    color: $greenHl;
    font-size: 2rem;
}

.test {
    color: $greenHl;
    font-weight: 700;
}

.totalAthlete {
    font-size: 2rem;
}

.testSummaryHead :global {

    th .label {
        height: fit-content !important;
    }
}