@use 'sass:math';
@import "./src/styles/Library";
.rating {
    position: relative;
    // height: 200px;

    .ratingLabel {
        border-top: 1px solid $light2;
        position: relative;
        height: 20px;
        box-sizing: border-box;
        color: $primary;
    }

    .bottomBorder {
        border-bottom: 1px solid $light2;
    }

    .ratingBar {
        width: 20px;
        background-color: $secondary;
        position: absolute;
        bottom: 0px;
        right: 8px;
        height: 0px;
        min-height: 1px;
    }
}

.left {
    text-align: right;
}

.textLabel {
    text-align: center;
}

.imageSymmetry {
    height: 190px;
    display: flex;
    justify-content: center;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.center {
    text-align: center;

}

.check {
    color: $secondary;
    width: 20px;
    height: 20px;
}

.close {
    color: $redHl;
    width: 20px;
    height: 20px;
}

.indicator {
    position: absolute;
    width: 80px;
}

.indicatorTorso {
    top: 10%;
}

.indicatorHip {
    top: 43%;
}

.indicatorHands {
    top: 28%;
}

.indicatorKnee {
    top: 65%;
}

.indicatorFoot {
    top: 86%;
}

.coreImage {
    width: 175px;

    img {
        width: 100%;
        height: 100%;
    }

}

.orangeAdequate {
    display: flex;
    align-items: center;
    gap: 5px;

    .circle {
        background-color: $yellowHl;
        width: 10px;
        height: 10px;
        border-radius: 5000px;
    }

    p {
        font-weight: 700;
        color: $primary;
    }
}

.redRestricted {
    display: flex;
    align-items: center;
    gap: 5px;

    .circle {
        background-color: $redHl;
        width: 10px;
        height: 10px;
        border-radius: 5000px;
    }

    p {
        font-weight: 700;
        color: $primary;
    }
}