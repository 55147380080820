@use 'sass:math';
@import "./src/styles/Library";
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem 0;

    .name {
        font-size: 1.1rem;
        font-weight: 700;
        flex-basis: 40%;
        color: $dark0;
    }

    .chart {
        width: 100%;
        height: 2.5rem;
        display: flex;
        gap: .5rem;
        overflow: hidden;
    }

    .bar {
        background-color: currentColor;
        height: 100%;
        min-width: 2px;
        animation-name: slideIn;
        animation-duration: 1.5s;
        animation-iteration-count: 1;
        transition: 1s ease all;
    }

    .label {
        font-weight: 700;
        font-size: 1.2rem;
        margin: auto 0;
        white-space: nowrap;
        color: currentColor;
        animation-name: slideInLabel;
        animation-duration: 2s;
        animation-iteration-count: 1;
    }

}

:global .rating-color-undefined .bar {
    background: transparent url(../../assets/images/gradient.png) repeat-y top left;
}

.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-heading {
        padding-bottom: var(--spacing-auto);
    }

}

.innerAthlete {
    border-top: 1px solid $panelBdr;
    border-bottom: 1px solid $panelBdr;
    background: $panelFooterBg;
    padding: var(--spacing-small) var(--spacing-auto);
}

.innerTeam :global {
    padding: var(--spacing-small) var(--spacing-small) var(--spacing-big);

    .arrow:after {
        width: 12px;
        height: 12px;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slideInLabel {
    from {
        transform: translateX(-150%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}