@use 'sass:math';
@import "./src/styles/Library";
.desc {}

.no-box {
    padding: 0;
    border: 0;
}

.no-border {
    border: 0;
}

.label {
    font-weight: 600;
    color: $primary;
}


.valueBox {
    margin-top: 2px;
    border: 1px solid #add8e6;
    overflow: hidden;
    padding: 3px;
    margin-bottom: 5px;
    min-height: 50px;
}

.box-240 {
    .valueBox {
        min-height: 240px;
    }
}

.summary {

    .label {
        font-size: 1.3rem;
    }

    .valueBox {
        padding: 10px 5px;
    }
}