@use 'sass:math';
@import "./src/styles/Library";
.image {
    background: $grayH1;
    width: 100%;
    height: 100%;
}

.heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: $medBlue;
}

.detail {
    font-weight: 700;
}