@use 'sass:math';
@import "./src/styles/Library";
.guide {
    background: $light1;
    border: 1px solid $light3;
    border-radius: 5px;
    padding: .5rem;
    margin-top: 1rem;

    .heading {
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.labels {
    .label {}

    .desc {
        font-weight: 500;
    }
}

.altLabel {
    padding-left: 0.3rem;
    font-weight: 600;
}

.boldText {
    font-weight: 600;
}