@use 'sass:math';
@import "./src/styles/Library";
.runtimes {
    padding: 0 3rem;
    position: relative;
}
.summary {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

.diff {
    color: $secondary;
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}

.index {
    position: relative;
    min-width: 90px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}

.index:hover {
    color: $primary;
}

.index :global {
    .form-field     {
        position: absolute;
        top: -12px;
        right: 0;
        width: 100%;
        min-width: 90px;
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
        text-decoration: none;
    }
     .form-field-select__control  {
        opacity: 0;
        cursor: pointer;
        height: 100%;
     }
     svg {
        padding-left: 2px;
     }
}