@use 'sass:math';
@import "./src/styles/Library";
//cover page
.bio :global {

    .girths {
        position: relative;
        max-width: 450px;
        margin: 0 auto;
        left: -25px;

        .image {
            max-width: 50%;
            margin: 0 auto;
        }

        .height {
            position: absolute;
            left: 10%;
            top: 0;
            text-align: right;
        }

        .inseam {
            position: absolute;
            left: 38%;
            top: 65%;
            text-align: right;
        }

        .arm {
            position: absolute;
            left: 76%;
            top: 24.5%;
            text-align: left;
        }

        .forearm {
            position: absolute;
            left: 76%;
            top: 38%;
            text-align: left;
        }

        .midThigh {
            position: absolute;
            left: 76%;
            top: 54%;
            text-align: left;
        }

        .calf {
            position: absolute;
            left: 76%;
            top: 76%;
            text-align: left;
        }

        .ankle {
            position: absolute;
            left: 76%;
            top: 89%;
            text-align: left;
        }

        .waist {
            position: absolute;
            left: 27%;
            top: 32%;
            text-align: right;
        }

        .hip {
            position: absolute;
            left: 27%;
            top: 41%;
            text-align: right;
        }
    }

    .skinfolds {
        position: relative;
        max-width: 450px;
        margin: 0 auto;

        .image {
            max-width: 50%;
            margin: 0 auto;
        }

        .bicep {
            position: absolute;
            right: 76%;
            top: 17%;
            text-align: right;
        }

        .tricep {
            position: absolute;
            left: 76%;
            top: 20%;
            text-align: left;
        }

        .midThigh {
            position: absolute;
            right: 76%;
            top: 65%;
            text-align: right;
        }

        .hip {
            position: absolute;
            right: 76%;
            top: 33%;
            text-align: right;
        }

        .abs {
            position: absolute;
            right: 76%;
            top: 48%;
            text-align: right;
        }

        .upperBack {
            position: absolute;
            left: 76%;
            top: 37%;
            text-align: left;
        }

        .calf {
            position: absolute;
            left: 76%;
            top: 67%;
            text-align: left;
        }

        .sum {
            position: absolute;
            left: 0%;
            top: 102%;
            text-align: center;
            max-width: 100%;
            width: 100%;
        }

        .footer {
            display: flex;
            justify-content: center;
            font-weight: 900;

            .label {
                color: $primary;
            }
        }
    }

    .bodyContainer {
        max-width: 450px;
        display: flex;
        margin: 0 auto;

        .body-mass {
            position: relative;

            .image {
                max-width: 100%;
                margin: 0 auto;
            }

            .bodyWeight {
                position: absolute;
                left: 0%;
                top: 102%;
                text-align: center;
                max-width: 100%;
                width: 100%;
            }

            .bodyMass {
                position: absolute;
                right: 76%;
                top: 8%;
                text-align: right;
            }

            .fatMass {
                position: absolute;
                right: 76%;
                top: 62.5%;
                text-align: right;
            }
        }

        .muscle-mass {
            position: relative;

            .image {
                max-width: 100%;
                margin: 0 auto;
            }

            .muscleMass {
                position: absolute;
                left: 0%;
                top: 102%;
                text-align: center;
                max-width: 100%;
                width: 100%;
            }

            .upperMuscle {
                position: absolute;
                right: 76%;
                top: 8%;
                text-align: right;
            }

            .lowerMuscle {
                position: absolute;
                right: 76%;
                top: 62.5%;
                text-align: right;
            }
        }
    }

}

.metricRanking :global {
    .measurement_Rr8dW {
        font-size: 2rem;
    }
}