@use 'sass:math';
@import "./src/styles/Library";
.heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;

    p {
        font-size: 1.55rem;
        font-weight: 600;
        color: $primary;
    }

    p span {
        color: $headingText;
    }

    img {
        width: 90px;
        height: auto;
        // height: 90px;
        // object-fit: scale-down;
        object-position: bottom;
    }
}

.around {
    @extend .heading;
    justify-content: space-around;
    margin-right: 3rem;

    .altText {
        font-size: 1.55rem;
        font-weight: 600;
        color: $primary;
    }
}


.has-image {
    min-height: 54px
}

.container {
    width: fit-content;
}

.seperator {
    background: rgba($light1, 50%);
    margin: 10px -20px 10px -20px;
    padding: 12px 20px;
    display: block;
    // width: 100%;
    border-bottom: 1.5px solid $primaryLight;
    border-top: 1.5px solid $primaryLight;
}