@use 'sass:math';
@import "./src/styles/Library";
.ratingsGrid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.8rem;
}

.ratingsGridItem {
    padding: .8rem;
    min-width: 20rem;
}