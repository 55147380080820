@use 'sass:math';
@import "./src/styles/Library";
.wrap {
    text-align: center;
}

.wrap :global svg {
    width: 100%;
    max-width: 120px;
    height: auto;
}

.wrap svg :global path {
    fill: #e6e7e8;
}

.FrontLeft_Shoulder :global #FrontLeft_Shoulder {
    fill: $redHl;
}

.FrontLeft_Biceps :global #FrontLeft_Biceps {
    fill: $redHl;
}

.FrontLeft_Forearm :global #FrontLeft_Forearm {
    fill: $redHl;
}

.FrontLeft_Wrist :global #FrontLeft_Wrist {
    fill: $redHl;
}

.FrontLeft_Hip :global #FrontLeft_Hip {
    fill: $redHl;
}

.FrontLeft_Lateral_Thigh :global #FrontLeft_Lateral_Thigh {
    fill: $redHl;
}

.FrontLeft_Quads :global #FrontLeft_Quads {
    fill: $redHl;
}

.FrontLeft_Knee :global #FrontLeft_Knee {
    fill: $redHl;
}

.FrontLeft_Shin :global #FrontLeft_Shin {
    fill: $redHl;
}

.FrontLeft_Foot :global #FrontLeft_Foot {
    fill: $redHl;
}

// right Side
.FrontRight_Shoulder :global #FrontRight_Shoulder {
    fill: $redHl;
}

.FrontRight_Biceps :global #FrontRight_Biceps {
    fill: $redHl;
}

.FrontRight_Forearm :global #FrontRight_Forearm {
    fill: $redHl;
}

.FrontRight_Wrist :global #FrontRight_Wrist {
    fill: $redHl;
}

.FrontRight_Hip :global #FrontRight_Hip {
    fill: $redHl;
}

.FrontRight_Lateral_Thigh :global #FrontRight_Lateral_Thigh {
    fill: $redHl;
}

.FrontRight_Quads :global #FrontRight_Quads {
    fill: $redHl;
}

.FrontRight_Knee :global #FrontRight_Knee {
    fill: $redHl;
}

.FrontRight_Shin :global #FrontRight_Shin {
    fill: $redHl;
}

.FrontRight_Foot :global #FrontRight_Foot {
    fill: $redHl;
}