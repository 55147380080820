@use 'sass:math';
@import "./src/styles/Library";
.positionImage {
    border-radius: 1rem !important;
    border: 1px solid $dark3;
    background-color: transparent;
}

.positionTable {
    border-right: 1px solid $light2;
}

.labelText {
    font-size: 1.1rem;
    font-weight: 600;
}

.inputWrapper :global {
    .form-field-label {
        flex-grow: 1;
    }

    .form-field-inner {
        justify-content: flex-end;
        flex-grow: 0;
    }

    input {
        width: 100px !important;
        justify-content: flex-end;
    }
}