@use 'sass:math';
@import "./src/styles/Library";
.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-heading h2 {
        justify-content: center;
        color: $medBlue;
    }

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }

    h3 {
        font-weight: 700;
    }
}

.container {
    padding: 2rem 2rem;
}

.inner:nth-child(2) {
    margin: 2rem -2rem;
    padding: 2rem 2rem;
    overflow: hidden;
    background: $panelFooterBg;
    border-bottom: 1px solid $panelBdr;
    border-top: 1px solid $panelBdr;
}

.list {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding-bottom: .5rem;
    font-weight: 600;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: currentColor;
}