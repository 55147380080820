@use 'sass:math';
@import "./src/styles/Library";
.container {
    width: 100%;
    display: flex;
    justify-content: center;

    .textParent {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 70%;

        p,
        strong {
            font-size: 1.5rem;
        }

        .text {
            color: $grayH1;
        }

        .check {
            color: $secondary;
        }

        .checkIcon {
            color: $secondary;
            width: 35px;
            height: 35px;
        }

        .up {
            color: $lightBlueText;
        }

        .upIcon {
            color: $lightBlueText;
            width: 35px;
            height: 35px;
        }

        .down {
            color: $yellowText;
        }

        .downIcon {
            color: $yellowText;
            width: 35px;
            height: 35px;
        }
    }
}

.mini {
    width: 100%;
    display: flex;
    justify-content: center;

    .textParent {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        strong {
            font-size: 1.1rem;
        }

        .text {
            // color: $grayH1;
        }

        .check {
            color: $secondary;
        }

        .checkIcon {
            color: $secondary;
            width: 20px;
            height: 20px;
        }

        .up {
            color: $lightBlueText;
        }

        .upIcon {
            color: $lightBlueText;
            width: 20px;
            height: 20px;
        }

        .down {
            color: $yellowText;
        }

        .downIcon {
            color: $yellowText;
            width: 20px;
            height: 20px;
        }
    }
}

.label {
    color: $primary;
}