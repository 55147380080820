@use 'sass:math';
@import "./src/styles/Library";
.text {
    color: $secondary;
    font-size: 1.6rem;
}

.blueText {
    color: $primary;
    font-size: 1.55rem;
    font-weight: 600;
}

.table :global {

    .rmwc-data-table__content {
        border: 1px transparent !important;

        .rmwc-data-table__body {

            .rmwc-data-table__cell {
                border: 1px transparent !important;
            }
        }
    }
}