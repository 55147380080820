@use 'sass:math';
@import "./src/styles/Library";
.arrow {
    color: $redHl;
    width: 30px;
    height: 30px;
}

.check {
    color: $greenHl;
    width: 30px;
    height: 30px;
}