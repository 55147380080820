@use 'sass:math';
@import "./src/styles/Library";
.label {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding-bottom: .5rem;
    font-weight: 700;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: currentColor;
}

.container {
    padding: 2rem 1rem;
    border-radius: 12px;
    background: $panelFooterBg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $panelBdr;
}

.container :global p {
    color: $dark0;
}

.panel :global {
    border-radius: 11px;
    border: 1px solid $panelBdr;
    background: $panelBg;
    box-shadow: 3px 3px 0 0 $light1;
    font-size: 1rem;

    .panel-heading h2 {
        justify-content: center;
        color: $medBlue;
    }

    .panel-body {
        background: $panelBg;
        border-radius: 11px;
    }
}