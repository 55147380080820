@use 'sass:math';
@import "./src/styles/Library";
.wrap {
    position: relative;
}

.wrap :global svg {
    width: 100%;
    max-width: 500px;
    height: auto;
}

.wrap :global {
    .left {
        position: absolute;
        left: 73%;
        top: 100%;
    }

    .right {
        position: absolute;
        right: 73%;
        top: 100%;
    }
}

// .wrap svg :global path {
//     fill: #e6e7e8;
// }

// //back
// .Back_Upper_Back :global #Back_Upper_Back {
//     fill: $redHl;
// }

// .Back_Lower_Back :global #Back_Lower_Back {
//     fill: $redHl;
// }

// .Back_Mid_Back :global #Back_Mid_Back {
//     fill: $redHl;
// }

// //left
// .BackLeft_Scapular :global #BackLeft_Scapular {
//     fill: $redHl;
// }

// .BackLeft_Gluteal :global #BackLeft_Gluteal {
//     fill: $redHl;
// }

// .BackLeft_Hamstring :global #BackLeft_Hamstring {
//     fill: $redHl;
// }

// .BackLeft_Calf :global #BackLeft_Calf {
//     fill: $redHl;
// }

// .BackLeft_Sole :global #BackLeft_Sole {
//     fill: $redHl;
// }

// //right

// .BackRight_Scapular :global #BackRight_Scapular {
//     fill: $redHl;
// }

// .BackRight_Gluteal :global #BackRight_Gluteal {
//     fill: $redHl;
// }

// .BackRight_Hamstring :global #BackRight_Hamstring {
//     fill: $redHl;
// }

// .BackRight_Calf :global #BackRight_Calf {
//     fill: $redHl;
// }

// .BackRight_Sole :global #BackRight_Sole {
//     fill: $redHl;
// }