@use 'sass:math';
@import "./src/styles/Library";
.reports {
    max-width: 1080px;
    margin: auto;
    position: relative;
    padding: 0 16px;
    min-height: 100vh;
}

.canvas {
    position: absolute;
    left: -100000px;
    top: -100000px;
}

.spacer {
    height: 48px;
}

.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 42px;
}

.menu :global {
    .inner {
        height: 42px;
        max-width: 960px;
        margin: auto;
        position: relative;
    }
}

.sidemenu {
    position: absolute;
    top: 24px;
    left: -150px;
    width: 140px;
    text-align: right;
}

.sidemenu :global {
    a {
        display: block;
        cursor: pointer;
        color: $dark3;
        font-weight: 500;
        padding: 2px 0;
        font-size: 13px;
    }

    a.active {
        color: $primary;
        font-weight: 600;
    }

}

.pdfButton {
    position: absolute;
    top: 20px;
    right: 20px;
}