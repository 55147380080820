@use 'sass:math';
@import "./src/styles/Library";
.check {
    height: 25px;
    display: block;
}

.icon {
    width: 25px;
    height: 25px;
    display: inline-block;
}

.iconChecked {
    color: $blueHl;
    width: 25px;
    height: 25px;
    display: inline-block;
}