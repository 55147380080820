// Overwrite Variables Here


$primary: #316FB5;
$secondary: #76CA3F;
$alt: #2acbd2;

$primaryLight: #EAF1F9;
$primaryDark: #272D60;

$secondaryLight: #EDFFE3;
$secondaryDark: #3B7F0E;

$purpleHl: #3D5AFE;
$purpleHl2: #e3c1ff;
$purpleHl3: #c681ff;
$yellowHl: #FFC107;
$goldHl: #fecf00;
$orangeHl: #FF5722;
$brownHl: #BE6F50;
$grayH1: #6c757d;
$greenHl: #5DC828;
$greenHl2: #2d7a00;
$blueHl: #359EFF;
$pinkHl: #e647be;
$redHl: #F5222D;
$redHl2: #F29A91;
$grayHl: #aebac1;

$darkBg: #4d4d6f; //#5f5f81

$fieldBdr : $light4;

$orangeText: #FC7226;
$yellowText :#FCD701;
$greenText :#62AE31;
$blueText :#387ABE;
$lightBlueText: #6bcffd;

$borderBlue : #DDF0F9;

$zone1Light: #0283C2;
$zone1Dark: darken($zone1Light, 10%);

$zone2Light: #76C93F;
$zone2Dark: darken($zone2Light, 12%);

$zone3Light: #4aae36;
$zone3Dark: darken($zone3Light, 12%);

$zone4Light: #fad101;
$zone4Dark: #EFB512;

$zone5Light: #FB853E;
$zone5Dark: darken($zone5Light, 16%);

$zone6Light: #E3301B;
$zone6Dark: darken($zone6Light, 10%);

//heading bg
$lightBlue: #2998cf;
$medBlue: #28659a;
$darkBlue: #283468;