@use 'sass:math';
@import "./src/styles/Library";
.container {

    .title {
        font-weight: 700;
        font-size: 1.2rem;
    }

    .charts {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

}

.chart {
    color: $light4;

    .subtitle {
        text-align: center;
        padding-bottom: 1rem;
        font-size: 1rem;
        width: 5rem;
        margin: auto;
        color: $dark0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .bar {
        position: relative;
        margin: 0 auto;
        width: 5rem;

        .dot {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: $darkBg;
            z-index: 1;
            border: 2px solid $light0;
        }

    }

    .column {
        position: relative;
        height: 200px;
        border-radius: 9px;
        background: $light1;
        overflow: hidden;

        .progress {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform-origin: bottom;
            background-color: currentColor;
            animation-name: slideIn;
            animation-duration: 1.5s;
            animation-iteration-count: 1;
            transition: 1s ease all;
        }

        .avgLine {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border-bottom: 3px dashed rgba($dark0, .5);
        }

        .avgLabel {
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translateX(-50%);
            width: 92%;
            text-align: center;
            color: rgba($dark0, .6);
        }

        .value {
            position: absolute;
            left: 50%;
            bottom: 5%;
            width: max-content;
            transform: translateX(-50%);
            font-weight: 700;
            font-size: 1.2rem;
            color: rgba($dark0, .8);

            span {
                font-size: .75em;
            }
        }
    }

    .label {
        padding-top: 1rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2;
        text-align: center;
        width: 5rem;
        color: currentColor;
        margin: auto;
    }

    .icon {
        padding-top: .5rem;
        text-align: center;
        font-size: 30px;
    }

    .iconDash {
        color: $dark3;
    }

    .iconUp {
        color: $greenHl;
    }

    .iconDown {
        color: $redHl;
    }

}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}